// ** React Imports
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../constant/route";
// import EditStyleProfile from "../../views/apps/styleProfile/EditStyleProfile";
import { ROLE } from "../../constant/constant";
import ArchivedProjects from "../../views/admin/archivedProjects/ArchivedProjects";
// import EditorsMasterTabs from "../../views/admin/editorsMaster/EditorsMasterTabs";
import SubAdmin from "../../views/admin/Coworkers/subAdmin/SubAdmin";
import CustomerSupport from "../../views/admin/Coworkers/customerSupport/CustomerSupport";
import AdminProfile from "../../views/admin/adminProfile/AdminProfile";
import CreateProjectStepperAdmin from "../../views/admin/projectManagement/createProjectSteps/CreateProjectStepperAdmin";
import ManageStatus from "../../views/admin/Settings/manageStatus/ManageStatus";
import StripeInfoComponent from "../../views/admin/adminProfile/StripeInfoComponent";
// pop-up
import {
  NewProjectPopup,
  OrderPagePopup,
  PrivacyPolicyPopup,
  SubscriptionPagePopup,
  TermsAndConditionsPopup,
} from "../../views/admin/popupManagement/indexPopupManagement";
import ComingSoon from "../../views/pages/misc/ComingSoon";

// import ClientDetail from "../../views/admin/clientManagement/clientDetail/ClientDetail";
// import ClientManagement from "../../views/admin/clientManagemen";

const ClientManagement = lazy(() =>
  import("../../views/admin/clientManagement")
);
const ClientDetail = lazy(() =>
  import("../../views/admin/clientManagement/clientDetail/ClientDetail")
);

const DiscountCoupon = lazy(() =>
  import("../../views/admin/discountCoupon/DiscountCouponTable")
);

const ProjectManagement = lazy(() =>
  import("../../views/admin/projectManagement")
);

const ProjectInfo = lazy(() =>
  import("../../views/admin/projectManagement/projectInfo/projectInfo")
);

const EditStyleProfile = lazy(() =>
  import("../../views/apps/styleProfile/EditStyleProfile")
);

const ProductMasterTable = lazy(() =>
  import("../../views/admin/productMaster/productMasterTable")
);

const ProductMasterAddEdit = lazy(() =>
  import(
    "../../views/admin/productMaster/productMasterAddEdit/productMasterAddEdit"
  )
);

// INTAKE & ORDER FORM
const IntakeOrderFormMain = lazy(() =>
  import("../../views/admin/IntakeOrderForm/IntakeOrderFormMain")
);

// Coworker
const CoworkerMain = lazy(() =>
  import("../../views/admin/coworker/coworkerMain")
);

const Dashboard = lazy(() => import("../../views/admin/dashboard/dashboard"));

const SubscriptionList = lazy(() =>
  import("../../views/admin/subscription/subscription-list/subscription-list")
);

const SubscriptionAddEdit = lazy(() =>
  import(
    "../../views/admin/subscription/subscription-add-edit/SubscriptionAddEdit"
  )
);

// [BO-275] Intake Form
const CustomIntakeFormForTest = lazy(() =>
  import("../../views/admin/TestFormBuilder/CustomIntakeFormForTest")
);

const AdminRoutes = [
  {
    element: <ClientManagement />,
    path: ADMIN_ROUTES.CLIENT_MANAGEMENT,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT],
  },
  // list subscription
  {
    element: <SubscriptionList />,
    path: ADMIN_ROUTES.SUBSCRIPTION,
    roles: [ROLE.SUPER_ADMIN],
  },
  // create and update subscription
  {
    element: <SubscriptionAddEdit />,
    path: ADMIN_ROUTES.CREATE_SUBSCRIPTION,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <DiscountCoupon />,
    path: ADMIN_ROUTES.DISCOUNT_COUPON,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <ClientDetail />,
    path: ADMIN_ROUTES.CLIENT_DETAIL,
    meta: {
      className: "client-detail",
    },
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },
  {
    element: <ProjectManagement />,
    path: ADMIN_ROUTES.PROJECT_MANAGEMENT,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },
  {
    element: <Dashboard />,
    path: ADMIN_ROUTES.DASHBOARD,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <CreateProjectStepperAdmin />,
    path: ADMIN_ROUTES.ADMIN_CREATE_PROJECT,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    meta: {
      className: "create-new-project-steps",
    },
  },

  {
    element: <ProjectInfo />,
    path: ADMIN_ROUTES.PROJECT_INFO,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },
  {
    element: <EditStyleProfile />,
    path: ADMIN_ROUTES.STYLE_PROFILE_VIEW,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },
  // {
  //   element: <PopupManagement />,
  //   path: ADMIN_ROUTES.POPUP_MAGEMENT,
  //   roles: [ROLE.SUPER_ADMIN],
  // },
  {
    element: <NewProjectPopup />,
    path: ADMIN_ROUTES.POPUP_MANAGEMENT_PROJECT,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <OrderPagePopup />,
    path: ADMIN_ROUTES.POPUP_MANAGEMENT_ORDER,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <SubscriptionPagePopup />,
    path: ADMIN_ROUTES.POPUP_MANAGEMENT_SUBSCRIPTION,
    roles: [ROLE.SUPER_ADMIN],
  },

  {
    element: <TermsAndConditionsPopup />,
    path: ADMIN_ROUTES.TERMS_OF_USE,
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    element: <PrivacyPolicyPopup />,
    path: ADMIN_ROUTES.PRIVACY_POLICY,
    roles: [ROLE.SUPER_ADMIN],
  },
  // {
  //   element: <EditorsMasterTabs />,
  //   path: ADMIN_ROUTES.EDITORS_MASTER,
  //   roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  // },

  {
    element: <ArchivedProjects />,
    path: ADMIN_ROUTES.ARCHIVED_PROJECTS,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT],
  },
  // {
  //   element: <SubAdmin />,
  //   path: ADMIN_ROUTES.COWORKER_ADMIN,
  //   roles: [ROLE.SUPER_ADMIN],
  // },
  // {
  //   element: <CustomerSupport />,
  //   path: ADMIN_ROUTES.COWORKER_CUSTOMER_SUPPORT,
  //   roles: [ROLE.SUPER_ADMIN],
  // },

  {
    element: <AdminProfile />,
    path: ADMIN_ROUTES.PROFILE,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },

  // INTAKE & ORDER FORM
  {
    element: <IntakeOrderFormMain />,
    path: ADMIN_ROUTES.INTAKE_ORDER_FORM,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },

  // PRODUCT-MASTER
  {
    element: <ProductMasterTable />,
    path: ADMIN_ROUTES.PRODUCT_MASTER,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    element: <ProductMasterAddEdit />,
    path: ADMIN_ROUTES.PRODUCT_MASTER_ADD_EDIT,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    element: <ManageStatus />,
    path: ADMIN_ROUTES.MANAGE_STATUS,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    element: <StripeInfoComponent />,
    path: ADMIN_ROUTES.STRIPE_INTEGRATION,
    roles: [ROLE.SUPER_ADMIN],
  },

  // CoworkerMain
  {
    element: <CoworkerMain />,
    path: ADMIN_ROUTES.COWORKER,
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },

  // [BO-275] Custom Intake Form
  {
    element: <CustomIntakeFormForTest />,
    path: ADMIN_ROUTES.ADMIN_CUSTOM_INTAKE_FORM,
    roles: [ROLE.USER, ROLE.SUPER_ADMIN],
  },
];

export default AdminRoutes;

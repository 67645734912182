import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIntakeOrderForm } from "../../services/IntakeOrderForm.service";

export const getIntakeOrderFormList = createAsyncThunk(
  "get/intake-order-form",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getIntakeOrderForm();
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

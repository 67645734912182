// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getStatusManagementTableList } from "./statusManagementList.thunk";

const initialState = {
  allStatusList: { isFetching: false, data: [], error: null },
};

const StatusManagementListSlice = createSlice({
  name: "statusManagement",
  initialState,
  extraReducers: (builder) => {
    // get all status // allStatusList
    builder.addCase(getStatusManagementTableList.pending, (state) => {
      state.allStatusList.data = [];
      state.allStatusList.isFetching = true;
    });

    builder.addCase(getStatusManagementTableList.fulfilled, (state, action) => {
      state.allStatusList.isFetching = false;
      state.allStatusList.data = action?.payload;
      state.allStatusList.error = null;
    });

    builder.addCase(getStatusManagementTableList.rejected, (state, action) => {
      state.allStatusList.isFetching = false;
      state.allStatusList.error = action?.payload?.message;
    });
  },
});

export default StatusManagementListSlice.reducer;

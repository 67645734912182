import authHttp from "./authHttp";

export const getCaretStatementList = async (val) => {
  const url = `api/dollar?page=${val}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const createCaret = async (payload) => {
  const url = "api/dollar";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getCaretStatementService = async () => {
  const url = "api/subscription-detail";
  const { data } = await authHttp.get(url);
  return data?.data;
};

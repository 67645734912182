// ** Navigation imports
import admin from "./admin";
import apps from "./apps";

// ** Merge & Export
export default [
  //   ...dashboards,
  ...apps,
  ...admin,
];

import authHttp from "./authHttp";

const status = "/api/status";

// get all status
export const getAllStatusList = async () => {
  const url = `${status}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

// create status
export const createStatus = async (payload) => {
  const url = `${status}`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// delete master status
export const deleteMasterStatus = async (id) => {
  const url = `${status}/${id}`;
  const { data } = await authHttp.del(url);
  return data;
};

// update status
export const updateStatusName = async (payload) => {
  const url = `${status}/update`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// update status order
export const updateStatusOrder = async (payload) => {
  const url = `${status}/order`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

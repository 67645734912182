/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
import React, { useMemo, useState } from "react";
// ** Cutome Component
import { yupResolver } from "@hookform/resolvers/yup";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import { selectThemeColors } from "@utils";
import { Check } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { getErrorMsg } from "../../../../../common/ErrorMessages/errorMessage";
import { getStatusManagementTableList } from "../../../../../redux/statusManagement/statusManagementList.thunk";
import {
  createStatus,
  updateStatusName,
} from "../../../../../services/statusManagement.service";
import ToastContent from "../../../../components/toast/toast";

const ValidationData = yup.object({
  name: yup
    .string()
    .required("Name is Required")
    .max(20, "Name should be not greater than 20 character"),

  next_action_id: yup
    .object()
    .when("is_client_approval_required", {
      is: (val) => val === true,
      then: yup
        .object()
        .test(
          "required",
          "Move the Approved Project to is Required",
          (value) => (value && value?.id !== "") || value !== null
        )
        .nullable(),
    })
    .nullable(),
});

const AddStatus = ({ isAddModal, setIsAddModal }) => {
  const dispatch = useDispatch();

  const statusListOptions = useSelector(
    (state) => state?.statusManagementList?.allStatusList?.data
  );

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = useMemo(
    () => ({
      name: isAddModal?.data?.name || "",
      color: isAddModal?.data?.color || "",
      enable_client_edit:
        isAddModal?.data?.enable_client_edit === "1" ? true : false,

      is_client_approval_required:
        isAddModal?.data?.is_client_approval_required === "1" ? true : false,
      next_action_id:
        isAddModal?.data?.is_client_approval_required === "1"
          ? statusListOptions?.find(
              (r) => r.id === isAddModal?.data?.next_action_id
            )
          : null,
    }),
    [isAddModal]
  );

  // ** Hooks

  const {
    // setValue,
    reset,
    // setError,
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({ defaultValues, resolver: yupResolver(ValidationData) });

  const ResetForm = () => {
    setIsAddModal({ isOpen: false, data: null });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    if (isAddModal?.data) {
      // edit
      const editPayload = {
        id: isAddModal?.data?.id,
        name: data?.name,
        enable_client_edit: data?.enable_client_edit ? "1" : "0",
        color: data?.color || "#000000",
        //
        is_client_approval_required: data?.is_client_approval_required
          ? "1"
          : "0",
        next_action_id: data?.is_client_approval_required
          ? data?.next_action_id?.id
          : null,
      };

      updateStatusName(editPayload)
        .then((res) => {
          ResetForm();
          dispatch(getStatusManagementTableList());
          toast((t) => (
            <ToastContent
              t={t.id}
              message={res.message}
              icon={<Check size={12} />}
            />
          ));
        })
        .catch((err) => {
          getErrorMsg(err);
        })
        .finally(setIsLoading(false));
    } else {
      // add
      const addPayload = {
        name: data?.name,
        enable_client_edit: data?.enable_client_edit ? "1" : "0",
        color: data?.color || "#000000",
        is_client_approval_required: data?.is_client_approval_required
          ? "1"
          : "0",
        next_action_id: data?.is_client_approval_required
          ? data?.next_action_id?.id
          : 0,
      };
      createStatus(addPayload)
        .then((res) => {
          ResetForm();
          dispatch(getStatusManagementTableList());
          toast((t) => (
            <ToastContent
              t={t.id}
              message={res.message}
              icon={<Check size={12} />}
            />
          ));
          console.log(res);
        })
        .catch((err) => {
          getErrorMsg(err);
        })
        .finally(setIsLoading(false));
    }
  };

  return (
    <>
      <Modal
        isOpen={isAddModal?.isOpen}
        toggle={ResetForm}
        className="modal-dialog-centered "
        // onClosed={() => setCardType("")}
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => ResetForm()}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <h1 className="text-center mb-1">
            {isAddModal?.data ? "Edit Status" : "Add Status"}
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs="12">
                <div className="mb-1">
                  <Label className="form-label" for="name">
                    Name
                  </Label>
                  <Controller
                    id="name"
                    name="name"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <Input
                        maxLength={20}
                        {...field}
                        type="text"
                        placeholder="Name"
                        // invalid={errors.name && true}
                      />
                    )}
                  />
                  {errors.name && (
                    <FormFeedback>{errors.name.message}</FormFeedback>
                  )}
                </div>
              </Col>
              <Col xs="12">
                <div className="mb-1 d-flex align-items-center">
                  <Label className="form-label me-1" for="color">
                    Color :
                  </Label>
                  <Controller
                    id="color"
                    name="color"
                    defaultValue=""
                    control={control}
                    render={({ field }) => <input {...field} type="color" />}
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="mb-1">
                  <Controller
                    name="enable_client_edit"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Input
                        id="enable_client_edit"
                        type="checkbox"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        className="me-50"
                      />
                    )}
                  />
                  <Label className="form-label me-1" for="enable_client_edit">
                    Can the Client Edit the Project Form?
                  </Label>
                </div>
              </Col>
              <Col xs="12">
                <div className="mb-1">
                  <Controller
                    name="is_client_approval_required"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Input
                        id="is_client_approval_required"
                        type="checkbox"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        className="me-50"
                      />
                    )}
                  />
                  <Label
                    className="form-label me-1"
                    for="is_client_approval_required"
                  >
                    Client Approval Required?
                  </Label>
                </div>
              </Col>
              {watch("is_client_approval_required") && (
                <Col xs="12" md="12">
                  <div className="mb-1">
                    <Label className="form-label" for="next_action_id">
                      Move the Approved Project to
                    </Label>
                    <Controller
                      id="next_action_id"
                      name="next_action_id"
                      defaultValue=""
                      control={control}
                      render={({
                        field: {
                          // value,
                          ...fieldProps
                        },
                      }) => (
                        <Select
                          {...fieldProps}
                          options={statusListOptions?.filter(
                            (val) => Number(isAddModal?.data?.id) !== val?.id
                          )}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(opt) => opt.id}
                          theme={selectThemeColors}
                          className="react-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    {errors.next_action_id && (
                      <FormFeedback>
                        {errors.next_action_id.message}
                      </FormFeedback>
                    )}
                  </div>
                </Col>
              )}

              <Col xs={12} className="mt-2 pt-50 text-center">
                <Button
                  type="submit"
                  className="me-1 "
                  color="primary"
                  disabled={isLoading || !isDirty}
                >
                  {isLoading
                    ? isAddModal?.data
                      ? "Updating..."
                      : "Creating..."
                    : "Save"}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddStatus;

import authHttp from "./authHttp";

const product = "/api/product";
const subProduct = "/api/sub-product";

// get app products
export const getAllProductsList = async () => {
  const url = `${product}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

// get single product
export const getSingleProduct = async (id) => {
  const url = `${product}/${id}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

// create product
export const createProduct = async (payload) => {
  const url = `${product}`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// delete master product
export const deleteMasterProduct = async (id) => {
  const url = `${product}/${id}`;
  const { data } = await authHttp.del(url);
  return data;
};

// update product name
export const updateProductName = async (payload) => {
  const url = `${product}/update`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// add or edit sub product
export const addEditSubProduct = async (payload) => {
  const url = `${subProduct}/save`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// delete sub product
export const deleteSubProduct = async (id) => {
  const url = `${subProduct}/${id}`;
  const { data } = await authHttp.del(url);
  return data;
};

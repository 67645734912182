import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllProductsList,
  getSingleProduct,
} from "../../services/productMaster.service";

export const getProductMasterTableList = createAsyncThunk(
  "get/product/list",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllProductsList();
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSingleProductList = createAsyncThunk(
  "get/single-product/list",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getSingleProduct(payload);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
// ** reactstrap
import { Badge, Card, Col, Label, Row } from "reactstrap";

// ** Third Party Components
import Select from "react-select";
// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
// ** Utils
import { selectThemeColors } from "@utils";

const SelectOption = [
  {
    id: 1,
    value: 1,
    label: "Instagram Video (1 Minute)",
    carats: "20 Coins",
    price: 249,
  },
  {
    id: 2,
    value: 2,
    label: "Music Highlight Video - Music Only (2 - 3 Minutes)",
    carats: "32 Coins",
    price: 399,
  },
  {
    id: 3,
    value: 3,
    label: "Music Highlight Video - Music Only (4 - 6 Minutes)",
    carats: "40 Coins",
    price: 499,
  },
  {
    id: 4,
    value: 4,
    label: "Narrative Highlight Video - Music + Dialogue (3 - 5 Minutes)",
    carats: "48 Coins",
    price: 599,
  },
  {
    id: 5,
    value: 5,
    label: "Narrative Highlight Video - Music + Dialogue (6 - 8 Minutes)",
    carats: "53 Coins",
    price: 669,
  },
  {
    id: 6,
    value: 6,
    label: "Narrative Highlight Video - Music + Dialogue (9 - 12 Minutes)",
    carats: "59 Coins",
    price: 739,
  },
  {
    id: 7,
    value: 7,
    label: "Cinematic Feature Film - Music + Dialogue (13 - 15 Minutes)",
    carats: "64 Coins",
    price: 799,
  },
  {
    id: 8,
    value: 8,
    label: "Cinematic Feature Film - Music + Dialogue (16 - 20 Minutes)",
    carats: "70 Coins",
    price: 879,
  },
  {
    id: 9,
    value: 9,
    label: "Cinematic Feature Film - Music + Dialogue (21 - 25 Minutes)",
    carats: "79 Coins",
    price: 989,
  },
  {
    id: 10,
    value: 10,
    label: "Documentary Edits (Ceremony Edit)",
    carats: "8 Coins",
    price: 99,
  },
  {
    id: 11,
    value: 11,
    label: "Documentary Edits (Reception Edit)",
    carats: "8 Coins",
    price: 99,
  },
  {
    id: 12,
    value: 12,
    label: "Documentary Edits (Dances Only)",
    carats: "6 Coins",
    price: 75,
  },
  {
    id: 13,
    value: 13,
    label: "Documentary Edits (Speeches Only)",
    carats: "6 Coins",
    price: 75,
  },
  {
    id: 14,
    value: 14,
    label: "Documentary Edits (Ceremony + Reception Edit)",
    carats: "12 Coins",
    price: 149,
  },
  {
    id: 15,
    value: 15,
    label: "Documentary Edits (Full Day Edit)",
    carats: "20 Coins",
    price: 249,
  },
];

const PlansListOption = [
  { value: 15, label: "Quartz - 15% Discount" },
  { value: 20, label: "Amber - 20% Discount" },
  { value: 25, label: "Sapphire - 25% Discount" },
  { value: 30, label: "Emerald - 30% Discount" },
  { value: 35, label: "Ruby - 35% Discount" },
  { value: 40, label: "Diamond - 40% Discount" },
];

const WPCalc = () => {
  const [planOptions, setPlanOptions] = useState({});
  const [plansList, setPlansList] = useState({});

  useEffect(() => {
    if (planOptions.price && plansList.value) {
      const percentage = (plansList.value / 100) * planOptions.price;
      const discountedPrice = planOptions.price - percentage;

      setPlanOptions((prevOptions) => ({
        ...prevOptions,
        discountedPrice,
      }));
    }
  }, [planOptions.price, plansList.value]);

  return (
    <div style={{ height: "100vh" }} className="calculator-div">
      <Row className="">
        <Col sm={6}>
          <Label for="SelectOptionList">All Products</Label>
          <Select
            id="SelectOptionList"
            isClearable={false}
            className="react-select"
            classNamePrefix="select"
            options={SelectOption}
            theme={selectThemeColors}
            onChange={(data) => {
              setPlanOptions(data);
            }}
          />
        </Col>
        <Col sm={6}>
          <Label for="PlansList">Membership Tier</Label>
          <Select
            id="PlansList"
            isClearable={false}
            className="react-select"
            classNamePrefix="select"
            options={PlansListOption}
            theme={selectThemeColors}
            onChange={(data) => {
              setPlansList(data);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        {planOptions.price && plansList.value && (
          <>
            <Col sm={12}>
              <Card className="calculator-info">
                <div className="d-flex m-1 mb-0">
                  <h3>A La Carte Price : </h3>
                  <h3 className="ms-1 pricing-info">
                    <span color="primary" style={{ color: "#4da69a" }}>
                      {planOptions.price !== undefined
                        ? `$${planOptions.price.toFixed(2)}`
                        : "-"}
                    </span>
                  </h3>
                </div>
                <div className="d-flex m-1 mb-0">
                  <h3>Discounted cost with Membership : </h3>
                  <h3 className="ms-1 pricing-info">
                    <span color="primary" style={{ color: "#4da69a" }}>
                      {planOptions.discountedPrice !== undefined
                        ? `$${planOptions.discountedPrice.toFixed(2)}` // Displaying the value with 2 decimal places
                        : "-"}
                    </span>
                  </h3>
                </div>
                <div className="d-flex m-1">
                  <h3>Cost of product in Coins : </h3>
                  <h3 className="ms-1 pricing-info">
                    <span color="primary" style={{ color: "#4da69a" }}>
                      {planOptions.carats ? planOptions.carats : "-"}
                    </span>
                  </h3>
                </div>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default WPCalc;

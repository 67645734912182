const ENVIRONMENTS = {
  DEV: "DEV",
  QA: "QA",
  UAT: "UAT",
  PROD: "PROD",
};

const ENVIRONMENT_FEATURES = {
  RECAPTCHA: "recaptcha",
  SENTRY: "sentry",
  NOT_ALLOW_TEST_KEYS: "not_allow_stripe_test_keys",
};

const ENVIRONMENT_FEATURES_ALLOWANCE = {
  // recaptcha
  [ENVIRONMENT_FEATURES.RECAPTCHA]: [],

  // sentry
  [ENVIRONMENT_FEATURES.SENTRY]: [],

  [ENVIRONMENT_FEATURES.NOT_ALLOW_TEST_KEYS]: [ENVIRONMENTS.PROD],
};

const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;

const environmentCheck = (feature) => {
  if (ENVIRONMENT_FEATURES_ALLOWANCE[feature].includes(currentEnvironment)) {
    return true;
  }
  return false;
};

export { environmentCheck, ENVIRONMENT_FEATURES, currentEnvironment };

/* eslint-disable multiline-ternary */
import authHttp from "./authHttp";

export const getSubscriptionsPlan = async (id) => {
  const url = id ? `api/subscriptions/${id}` : "api/subscriptions";
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};

export const deleteSubscriptionsPlan = async (id) => {
  const url = `/api/subscriptions/${id}`;
  const { data } = await authHttp.del(url);
  return data;
};

export const createSubscriptionsPlan = async (value) => {
  const url = `/api/subscriptions`;
  const { data } = await authHttp.post(url, value);
  return data;
};

export const updateSubscriptionsPlan = async (id, value) => {
  const url = `/api/subscriptions/${id}`;
  const { data } = await authHttp.patch(url, value);
  return data;
};

export const createStripeCustomer = async (value, subscriptionId) => {
  const url = subscriptionId
    ? "api/update-subscription"
    : "api/subscription-purchase";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getClientSecret = async () => {
  const url = "/api/client-secret";
  const { data } = await authHttp.get(url);
  return data;
};

export const createPaymentMethod = async (value) => {
  const url = "/api/payment-method/store";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const calculateAmount = async (value) => {
  const url = "/api/calculate-amount";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const payRemainingAmount = async (value) => {
  const url = "/api/pay-amount";
  const { data } = await authHttp.post(url, value);
  return data;
};

//

export const calculateProrate = async (value) => {
  const url = "/api/calculate-prorate";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const upgradeSubscription = async (value) => {
  const url = "/api/pay-prorate";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const confirmPayment = async (value) => {
  const url = "api/confirm-payment-3d";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const cancelUserSubscription = async (value) => {
  const url = "api/cancel-subscription";
  const { data } = await authHttp.post(url, value);
  return data;
};

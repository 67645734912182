import authHttp from "./authHttp";

export const getAllPopUpMessage = async () => {
  const url = `/api/get-pop-up-messages`;
  const { data } = await authHttp.get(url);
  return data.data;
};
export const getParticularPopUpMessage = async (value) => {
  const url = `/api/get-pop-up-message/${value}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

export const UpdatePopUpMessage = async (payload) => {
  const url = `/api/update-pop-up-message`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

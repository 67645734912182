import authHttp from "./authHttp";

const INTAKE_ORDER_FORM = "/api/intake-order-form";

// get all INTAKE_ORDER_FORM
export const getIntakeOrderForm = async () => {
  const url = `${INTAKE_ORDER_FORM}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

// save INTAKE_ORDER_FORM
export const updateIntakeOrderForm = async (val) => {
  const url = `${INTAKE_ORDER_FORM}`;
  const res = await authHttp.post(url, val);
  return res;
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProjectList } from "../../services/projectManagementService";

export const getAllDataProjectManagement = createAsyncThunk(
  "get/projects/list",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getAllProjectList(payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

/* eslint-disable multiline-ternary */
import { USER_PLAN_TYPE } from "../views/apps/myprojects/createProjectSteps/utils";
import authHttp from "./authHttp";

// **  Get Current User
export const createProject = async (value, userType) => {
  const url =
    userType === USER_PLAN_TYPE.SUBSCRIBER
      ? "/api/create-project/subscriber"
      : "/api/create-project/alacarte";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const addDiscount = async (value) => {
  const url = "api/verify-discount-coupon";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const addLinkService = async (payload) => {
  const url = `api/add-link?sub_project_id=${payload.id}`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const removeLinkService = async (payload) => {
  const url = `/api/remove-link`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getPublisherKey = async () => {
  const url = `/api/publisher-key`;
  const { data } = await authHttp.get(url);
  return data;
};

export const createPaymentIntent = async (payload) => {
  const url = `/api/create-payment-intent`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

// ** React Imports
import { lazy } from "react";
import { AUTH_ROUTES } from "../../constant/route";
import WPCalc from "../../views/WPCalc/WPCalc";
import VerifyEmailBasic from "../../views/pages/authentication/VerifyEmailBasic";
import { ROLE } from "../../constant/constant";
import SettingWorkspace from "../../views/pages/authentication/SettingWorkspace";
// import VerifyEmailBasic from "../../views/pages/authentication/VerifyEmailBasic";

// **  Uncut-user-login
const LoginUncut = lazy(() =>
  import("../../views/pages/authentication/LoginUncut")
);

// ** uncut-user-register
const Register = lazy(() =>
  import("../../views/pages/authentication/RegisterUncut")
);

// ** Admin Login Path
const LoginUncutAdmin = lazy(() =>
  import("../../views/pages/authentication/LoginUncutAdmin")
);

const ResetPasswordUncut = lazy(() =>
  import("../../views/pages/authentication/ResetPasswordUncut")
);

const AuthenticationRoutes = [
  // ** uncut-user
  {
    path: AUTH_ROUTES.APP_LOGIN,
    element: <LoginUncut />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    roles: [],
  },
  {
    path: AUTH_ROUTES.APP_REGISTER,
    element: <Register />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    roles: [],
  },

  // ** Uncut-Admin
  // {
  //   path: AUTH_ROUTES.ADMIN_LOGIN,
  //   element: <LoginUncutAdmin />,
  //   meta: {
  //     layout: "blank",
  //     publicRoute: true,
  //     restricted: true,
  //   },
  //   roles: [],
  // },
  {
    path: AUTH_ROUTES.RESET_PASSWORD,
    element: <ResetPasswordUncut />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    roles: [],
  },
  {
    path: "/pages/verify-email",
    element: <VerifyEmailBasic />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
    roles: [],
  },
  // ** calculator Component

  {
    path: "/pages/thanks",
    element: <SettingWorkspace />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
    roles: [],
  },

  {
    path: AUTH_ROUTES.CALCULATOR,
    element: <WPCalc />,
    meta: {
      layout: "blank",
      publicRoute: true,
      // restricted: true,
    },
    roles: [],
  },
];

export default AuthenticationRoutes;

import { USER_PLAN_TYPE } from "../../views/apps/myprojects/createProjectSteps/utils";
import authHttp from "../authHttp";

export const createProjectAdmin = async (value, userType) => {
  // const url = `api/create-project-admin`;
  const url = userType
    ? "api/create-project/subscriber"
    : "api/create-project-admin";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getClientList = async (payload) => {
  const url = `api/all-users`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

import authHttp from "../authHttp";

export const createCoworkerUser = async (value) => {
  const url = "/api/admin-user";
  const { data } = await authHttp.post(url, value);
  return data;
};

export const getCoworkersList = async (val) => {
  const url = `/api/get-admin-user/${val}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteCoworkers = async (id) => {
  const url = `/api/delete-admin-user/${id}`;
  const { data } = await authHttp.del(url);
  return data;
};

export const getEditorCompaniesList = async () => {
  const url = `/api/get-user-companies/editores`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getCustomerSupportCompaniesList = async () => {
  const url = `/api/get-user-companies/coworker`;
  const { data } = await authHttp.get(url);
  return data;
};

export const UnAssgineCompanyCustomerSupport = async (id) => {
  const url = `/api/unassgine-company/coworker/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateAdminUser = async (value) => {
  const url = `/api/update-admin-user`;
  const { data } = await authHttp.post(url, value);
  return data;
};

import authHttp from "../authHttp";

// **  Get stripe-keys
export const getStripeKeys = async () => {
  const url = `/api/stripe-keys`;
  const { data } = await authHttp.get(url);
  return data;
};

export const updateStripeKeys = async (val) => {
  const url = "/api/stripe-keys";
  const res = await authHttp.post(url, val);
  return res;
};

import { createSlice } from "@reduxjs/toolkit";
import { getIntakeOrderFormList } from "./IntakeOrderFormList.thunk";
import { checkArray } from "../../views/dynamic-form-fields/FormValidationFunction";

const initialState = {
  IntakeOrderFormListData: {
    isFetching: false,
    data: {},
    combine_data: [],
    error: null,
    api_called: 0,
  },
};

const IntakeOrderFormListListSlice = createSlice({
  name: "IntakeOrderFormList",
  initialState,
  extraReducers: (builder) => {
    // get all status // intakeOrderFormList
    builder.addCase(getIntakeOrderFormList.pending, (state) => {
      state.IntakeOrderFormListData.data = {};
      state.IntakeOrderFormListData.combine_data = [];
      state.IntakeOrderFormListData.isFetching = true;
      state.IntakeOrderFormListData.api_called = 0;
    });

    builder.addCase(getIntakeOrderFormList.fulfilled, (state, action) => {
      state.IntakeOrderFormListData.isFetching = false;
      state.IntakeOrderFormListData.data = action?.payload;
      state.IntakeOrderFormListData.combine_data = [
        ...(checkArray(action?.payload?.intake_form)
          ? action?.payload?.intake_form
          : []),
        ...(checkArray(action?.payload?.order_form)
          ? action?.payload?.order_form
          : []),
      ];
      state.IntakeOrderFormListData.error = null;
      state.IntakeOrderFormListData.api_called = 1;
    });

    builder.addCase(getIntakeOrderFormList.rejected, (state, action) => {
      state.IntakeOrderFormListData.isFetching = false;
      state.IntakeOrderFormListData.error = action?.payload?.message;
      state.IntakeOrderFormListData.api_called = 1;
    });
  },
});

export default IntakeOrderFormListListSlice.reducer;

// ** React Imports
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MAINTENANCE_ROUTE, PAGE_NOT_FOUND } from "../../constant/route";
import { ROLE } from "../../constant/constant";
import Error from "../../views/pages/misc/Error";
const Maintenance = lazy(() => import("../../views/pages/misc/Maintenance"));

const MaintenanceRoutes = [
  // UncutGems Route
  {
    element: <Maintenance />,
    path: MAINTENANCE_ROUTE,
    meta: {
      layout: "blank",
      publicRoute: true,
      //   restricted: true,
    },
    roles: [],
  },
  {
    element: <Error />,
    path: PAGE_NOT_FOUND,
    meta: {
      layout: "blank",
      publicRoute: true,
      //   restricted: true,
    },
    roles: [],
  },
];

export default MaintenanceRoutes;

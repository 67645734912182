// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getAllDataProjectManagement } from "./allDataProjectManagement.thunk";

const initialState = {
  allProjectList: { isFetching: false, data: [], error: null },
};

const AllDataProjectManagementSlice = createSlice({
  name: "allDataProjectManagement",
  initialState,
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getAllDataProjectManagement.pending, (state) => {
      state.allProjectList.data = [];
      state.allProjectList.isFetching = true;
    });

    builder.addCase(getAllDataProjectManagement.fulfilled, (state, action) => {
      state.allProjectList.isFetching = false;
      state.allProjectList.data = action?.payload;
      state.allProjectList.error = null;
    });

    builder.addCase(getAllDataProjectManagement.rejected, (state, action) => {
      state.allProjectList.isFetching = false;
      state.allProjectList.error = action?.payload?.message;
    });
  },
});

export default AllDataProjectManagementSlice.reducer;

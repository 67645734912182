import { createSlice } from "@reduxjs/toolkit";

export const domainDataStates = createSlice({
  name: "domainDataStates",
  initialState: {
    domainData: null,
  },
  reducers: {
    setDomainData: (state, action) => {
      state.domainData = action.payload;
    },
  },
});

export const { setDomainData } = domainDataStates.actions;

export default domainDataStates.reducer;

import axios from "axios";

axios.interceptors.request.use(async (config) => {
  config.baseURL = process.env.REACT_APP_BASENAME;
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
};

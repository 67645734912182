// ** Icons Import
import {
  Briefcase,
  CreditCard,
  DollarSign,
  ExternalLink,
  Home,
  Layout,
  Settings,
  ShoppingCart,
  Users,
} from "react-feather";
import { ReactComponent as UnArchiveIcon } from "../../assets/images/pages/unarchive.svg";
import { ROLE } from "../../constant/constant";
import { ADMIN_ROUTES } from "../../constant/route";

import { ReactComponent as Percent } from "../../assets/images/svg/percent-outline.svg";

export default [
  // {
  //   id: "custom-intake-form",
  //   title: "USER Custom Intake Form",
  //   icon: <User size={20} />,
  //   navLink: USER_ROUTES.CUSTOM_INTAKE_FORM,
  //   role: [ROLE.USER, ROLE.SUPER_ADMIN],
  // },
  // {
  //   id: "custom-intake-form-admin-side",
  //   title: "ADMIN Custom Intake Form",
  //   icon: <User size={20} />,
  //   navLink: ADMIN_ROUTES.ADMIN_CUSTOM_INTAKE_FORM,
  //   role: [ROLE.USER, ROLE.SUPER_ADMIN],
  // },
  {
    header: "Activity",
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
  },

  {
    id: "Home",
    title: "Home",
    icon: <Home size={20} />,
    role: [ROLE.SUPER_ADMIN],
    navLink: ADMIN_ROUTES.DASHBOARD,
  },
  {
    id: "Order",
    title: "Order",
    icon: <ShoppingCart size={12} />,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR],
    navLink: ADMIN_ROUTES.PROJECT_MANAGEMENT,
  },
  {
    id: "ArchivedProjects",
    title: "Archived",
    icon: <UnArchiveIcon size={12} />,
    navLink: ADMIN_ROUTES.ARCHIVED_PROJECTS,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT],
  },
  {
    header: "Team",
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    id: "coworker",
    title: "Coworker",
    icon: <Users size={20} />,
    navLink: ADMIN_ROUTES.COWORKER,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },

  {
    header: "Billing",
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT],
  },

  {
    id: "Clients",
    title: "Clients",
    icon: <Users />,
    navLink: ADMIN_ROUTES.CLIENT_MANAGEMENT,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT],
  },
  {
    id: "Subscription",
    title: "Subscription",
    icon: <DollarSign />,
    role: [ROLE.SUPER_ADMIN],
    navLink: ADMIN_ROUTES.SUBSCRIPTION,
    // disabled: true,
  },

  {
    header: "Marketing",
    role: [ROLE.SUPER_ADMIN],
  },
  {
    id: "Coupons",
    title: "Coupons",
    icon: <Percent />,
    navLink: ADMIN_ROUTES.DISCOUNT_COUPON,
    role: [ROLE.SUPER_ADMIN],
  },

  {
    header: "Client Project Setup",
    role: [ROLE.SUPER_ADMIN],
  },

  {
    id: "NewProjectPopup",
    title: "New Order Popup",
    icon: <ExternalLink />,
    navLink: ADMIN_ROUTES.POPUP_MANAGEMENT_PROJECT,
    role: [ROLE.SUPER_ADMIN],
  },
  {
    id: "OrderPageTC",
    title: "Order Page T&C",
    icon: <ExternalLink size={12} />,
    navLink: ADMIN_ROUTES.POPUP_MANAGEMENT_ORDER,
    role: [ROLE.SUPER_ADMIN],
  },
  {
    id: "SubscriptionPageTC",
    title: "Subscription Page T&C",
    icon: <ExternalLink size={12} />,
    navLink: ADMIN_ROUTES.POPUP_MANAGEMENT_SUBSCRIPTION,
    role: [ROLE.SUPER_ADMIN],
  },
  {
    id: "T&C",
    title: "T&C",
    icon: <ExternalLink size={12} />,
    role: [ROLE.SUPER_ADMIN],
    navLink: ADMIN_ROUTES.TERMS_OF_USE,
  },
  {
    id: "PrivacyPolicy",
    title: "Privacy Policy",
    icon: <ExternalLink size={12} />,
    navLink: ADMIN_ROUTES.PRIVACY_POLICY,
    role: [ROLE.SUPER_ADMIN],
  },

  {
    header: "Setup",
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    id: "intake_order_form",
    title: "Intake & Order Form",
    icon: <Layout size={12} />,
    navLink: ADMIN_ROUTES.INTAKE_ORDER_FORM,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    id: "Service",
    title: "Service",
    icon: <Settings size={12} />,
    navLink: ADMIN_ROUTES.PRODUCT_MASTER,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    id: "WorkFlow",
    title: "Work Flow",
    icon: <Briefcase size={12} />,
    navLink: ADMIN_ROUTES.MANAGE_STATUS,
    role: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
  },
  {
    id: "Payments",
    title: "Payments",
    icon: <CreditCard size={12} />,
    navLink: ADMIN_ROUTES.STRIPE_INTEGRATION,
    role: [ROLE.SUPER_ADMIN],
  },
];

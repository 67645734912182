import axios from "axios";

axios.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem("accessToken");
  config.baseURL = process.env.REACT_APP_BASENAME;
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  del: axios.delete,
  patch: axios.patch,
};

import authHttp from "../authHttp";

export const getProjectEdit = async (id) => {
  const url = `api/fetch-create-project/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const orderForSubscriber = async (payload) => {
  const url = "api/subscriber/additional-edit";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const orderForAlacarte = async (payload) => {
  const url = "api/alacarte/additional-edit";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const addDiscountAdditionalEdit = async (value) => {
  const url = "api/verify-discount-coupon-ae";
  const { data } = await authHttp.post(url, value);
  return data;
};

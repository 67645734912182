// ** React Imports
import { Fragment, useEffect } from "react";

// ** Routes Imports
import AppRoutes from "./Apps";
import AdminRoutes from "./Admin";
import PagesRoutes from "./Pages";
import AuthenticationRoutes from "./Authentication";

// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";
import PrivateRoute from "@components/routes/PrivateRoute";

// ** Utils
import { isObjEmpty } from "@utils";
import { ADMIN_ROUTES, USER_ROUTES } from "../../constant/route";
import ErrorBoundaryFallback from "../../common/ErrorBoundaryFallback/ErrorBoundaryFallback";
import * as Sentry from "@sentry/react";
import StripeElement from "../../common/stripeComponent/StripeElement";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getUserData } from "../../utility/Utils";
import { useNavigate } from "react-router-dom";
import { ROLE } from "../../constant/constant";
import MaintenanceRoutes from "./Maintenance";

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - Blinkovo";

// ** Default Route
const UserRoute = USER_ROUTES.APP_HOME;

const AdminRoute = ADMIN_ROUTES.PROJECT_MANAGEMENT;
// const AdminRoute = ADMIN_ROUTES.DASHBOARD;

// ** Merge Routes
const Routes = [
  ...AuthenticationRoutes,
  ...AppRoutes,
  ...PagesRoutes,
  ...AdminRoutes,
  ...MaintenanceRoutes,
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const currentUserRole = getUserData()?.role;
  const LayoutRoutes = [];
  const navigate = useNavigate();

  const params = new URLSearchParams(location?.search);
  const useremail = params.has("email");

  useEffect(() => {
    if (currentUserRole === ROLE.USER && useremail) {
      navigate(USER_ROUTES.MY_PROJECTS);
    }
  }, [useremail]);

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          // Check if the user's role matches the allowed roles for the route
          const canAccessRoute = route?.roles?.includes(currentUserRole);

          // Render the route only if the user has the required role
          if (canAccessRoute || route?.roles?.length === 0) {
            const Wrapper =
              // eslint-disable-next-line multiline-ternary
              isObjEmpty(route.element.props) && isBlank === false
                ? // eslint-disable-next-line multiline-ternary
                  LayoutWrapper
                : Fragment;

            route.element = (
              <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                {RouteTag === PublicRoute ? (
                  <>
                    <GoogleReCaptchaProvider
                      reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                    >
                      <RouteTag route={route}>
                        <Sentry.ErrorBoundary
                          beforeCapture={(scope) => {
                            scope.setTag("route", "src/router/routes/index.js");
                          }}
                          fallback={({ error, componentStack, resetError }) => (
                            <ErrorBoundaryFallback
                              error={error}
                              componentStack={componentStack}
                              resetError={resetError}
                            />
                          )}
                        >
                          {route.element}
                        </Sentry.ErrorBoundary>
                      </RouteTag>
                    </GoogleReCaptchaProvider>
                  </>
                ) : (
                  <>
                    <RouteTag route={route}>
                      <Sentry.ErrorBoundary
                        beforeCapture={(scope) => {
                          scope.setTag("route", "src/router/routes/index.js");
                        }}
                        fallback={({ error, componentStack, resetError }) => (
                          <ErrorBoundaryFallback
                            error={error}
                            componentStack={componentStack}
                            resetError={resetError}
                          />
                        )}
                      >
                        {route.element}
                      </Sentry.ErrorBoundary>
                    </RouteTag>
                  </>
                )}
              </Wrapper>
            );
          } else {
            return null; // Route is hidden for this user
          }
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { UserRoute, AdminRoute, TemplateTitle, Routes, getRoutes };
